import React from "react"

export default function about(props) {
    return (
            <div className="bg-blue-900">
				<div className="container mx-auto">
					
				</div>
			</div>
    )
}